import { Navbar } from "./assets/components/Navbar"
import { Hero } from "./assets/components/Hero"
import {Industrias} from "./assets/components/Industrias"
import { Footer } from "./assets/components/Footer"
import { Whatsapp } from "./assets/components/Whatsapp"

export const App = () => {

  return (
    <>      
        <Navbar />
        <Hero/>
        <Industrias/>
        <Whatsapp/>
        <Footer/>
    </>
  )
}
