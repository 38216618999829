import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import VideoAutomotriz from "../videos/automotriz.mp4";
import VideoQuimica from "../videos/quimica.mp4";
import VideoFundicion from "../videos/fundicion.mp4";
import VideoMetalmecanica from "../videos/metalmecanica.mp4";
import VideoAlimentaria from "../videos/alimentaria.mp4";
import { VideoIndustria } from "./VideoIndustria";

export const Industrias = () => {
  const animacionTitulo = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const animacionTitulo1 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const animacionTextos1 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const animacionTitulo2 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const animacionTextos2 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const animacionTitulo3 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const animacionTextos3 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const animacionTitulo4 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const animacionTextos4 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const animacionTitulo5 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const animacionTextos5 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controlTitulo = useAnimation();
  const [refTitulo, inViewTitulo] = useInView();

  const controlTitulo1 = useAnimation();
  const [refTitulo1, inViewTitulo1] = useInView();
  const controlTextos1 = useAnimation();
  const [refTextos1, inViewTextos1] = useInView();

  const controlTitulo2 = useAnimation();
  const [refTitulo2, inViewTitulo2] = useInView();
  const controlTextos2 = useAnimation();
  const [refTextos2, inViewTextos2] = useInView();

  const controlTitulo3 = useAnimation();
  const [refTitulo3, inViewTitulo3] = useInView();
  const controlTextos3 = useAnimation();
  const [refTextos3, inViewTextos3] = useInView();

  const controlTitulo4 = useAnimation();
  const [refTitulo4, inViewTitulo4] = useInView();
  const controlTextos4 = useAnimation();
  const [refTextos4, inViewTextos4] = useInView();

  const controlTitulo5 = useAnimation();
  const [refTitulo5, inViewTitulo5] = useInView();
  const controlTextos5 = useAnimation();
  const [refTextos5, inViewTextos5] = useInView();

  useEffect(() => {
    if (inViewTitulo) {
      controlTitulo.start("visible");
    } else {
      controlTitulo.start("hidden");
    }
  }, [controlTitulo, inViewTitulo]);

  useEffect(() => {
    if (inViewTitulo1) {
      controlTitulo1.start("visible");
    } else {
      controlTitulo1.start("hidden");
    }
  }, [controlTitulo1, inViewTitulo1]);
  useEffect(() => {
    if (inViewTextos1) {
      controlTextos1.start("visible");
    } else {
      controlTextos1.start("hidden");
    }
  }, [controlTextos1, inViewTextos1]);

  useEffect(() => {
    if (inViewTitulo2) {
      controlTitulo2.start("visible");
    } else {
      controlTitulo2.start("hidden");
    }
  }, [controlTitulo2, inViewTitulo2]);
  useEffect(() => {
    if (inViewTextos2) {
      controlTextos2.start("visible");
    } else {
      controlTextos2.start("hidden");
    }
  }, [controlTextos2, inViewTextos2]);

  useEffect(() => {
    if (inViewTitulo3) {
      controlTitulo3.start("visible");
    } else {
      controlTitulo3.start("hidden");
    }
  }, [controlTitulo3, inViewTitulo3]);
  useEffect(() => {
    if (inViewTextos3) {
      controlTextos3.start("visible");
    } else {
      controlTextos3.start("hidden");
    }
  }, [controlTextos3, inViewTextos3]);

  useEffect(() => {
    if (inViewTitulo4) {
      controlTitulo4.start("visible");
    } else {
      controlTitulo4.start("hidden");
    }
  }, [controlTitulo4, inViewTitulo4]);
  useEffect(() => {
    if (inViewTextos4) {
      controlTextos4.start("visible");
    } else {
      controlTextos4.start("hidden");
    }
  }, [controlTextos4, inViewTextos4]);

  useEffect(() => {
    if (inViewTitulo5) {
      controlTitulo5.start("visible");
    } else {
      controlTitulo5.start("hidden");
    }
  }, [controlTitulo5, inViewTitulo5]);
  useEffect(() => {
    if (inViewTextos5) {
      controlTextos5.start("visible");
    } else {
      controlTextos5.start("hidden");
    }
  }, [controlTextos5, inViewTextos5]);

  return (
    <>
      <section id="industrias">
        <div className="container mx-auto py-7 px-3 md:px-6">
          <motion.div
            ref={refTitulo}
            variants={animacionTitulo}
            initial="hidden"
            animate={controlTitulo}
          >
            <h1 className="title titulo-industrias text-center md:px-6 mb-3">
              Captura, Filtración y Transporte de Polvos, Humos y Neblinas
              generadas por procesos industriales.
            </h1>
          </motion.div>
          <hr className="divider" />
          <div className="industria flex-col flex md:flex-row-reverse my-10 md:items-end md:px-6">
            <VideoIndustria src={VideoFundicion} />
            <div className="industria-description md:w-1/2 md:pr-3 lg:pr-5">
              <motion.div
                ref={refTitulo1}
                variants={animacionTitulo1}
                initial="hidden"
                animate={controlTitulo1}
              >
                <h2 className="title text-justify mb-3">
                  Industria Metalúrgica
                </h2>
                <h3 className="subtitle text-justify mb-3">
                  Las aplicaciones de filtración más comunes son para:
                </h3>
              </motion.div>
              <motion.div
                ref={refTextos1}
                variants={animacionTextos1}
                initial="hidden"
                animate={controlTextos1}
              >
                <ul className="list-disc pl-5 mb-5 text-justify">
                  <li className="text">Hornos fusores y de retención.</li>
                  <li className="text">Estaciones de colada.</li>
                  <li className="text">Desmoldeo.</li>
                  <li className="text">
                    Inyectoras de aluminio (Die casting).
                  </li>
                  <li className="text">Adición de aleaciones y fundentes.</li>
                  <li className="text">Escoriación.</li>
                </ul>
              </motion.div>
            </div>
          </div>
          <hr className="divider" />
          <div className="industria flex-col flex my-10 md:flex-row md:items-end md:px-6">
            <VideoIndustria src={VideoAutomotriz} />
            <div className="industria-description md:w-1/2 md:pl-3 lg:pl-10">
              <motion.div
                ref={refTitulo2}
                variants={animacionTitulo2}
                initial="hidden"
                animate={controlTitulo2}
              >
                <h2 className="title text-justify mb-3">
                  Industria Automotriz
                </h2>
                <h3 className="subtitle text-justify mb-3">
                  Captura, transporte y filtración de contaminantes en procesos
                  como:
                </h3>
              </motion.div>
              <motion.div
                ref={refTextos2}
                variants={animacionTextos2}
                initial="hidden"
                animate={controlTextos2}
              >
                <ul className="list-disc pl-5 mb-5 text-justify">
                  <li className="text">Humos de soldadura en robot.</li>
                  <li className="text">
                    Humos de soldadura con microalamabre.
                  </li>
                  <li className="text">Venteos en mezclador Bambury.</li>
                  <li className="text">Emisiones en cabinas de pintura.</li>
                  <li className="text">
                    Polvos emitidos en cabinas de esmerilado o pulido.
                  </li>
                  <li className="text">Nieblas en proceso de maquinado CNC.</li>
                </ul>
              </motion.div>
            </div>
          </div>
          <hr className="divider" />
          <div className="industria flex-col flex md:flex-row-reverse my-10 md:items-end md:px-6">
            <VideoIndustria src={VideoMetalmecanica} />
            <div className="industria-description md:w-1/2 md:pr-3 lg:pr-5">
              <motion.div
                ref={refTitulo3}
                variants={animacionTitulo3}
                initial="hidden"
                animate={controlTitulo3}
              >
                <h2 className="title text-justify mb-3">
                  Industria Metalmecánica
                </h2>
                <h3 className="subtitle text-justify mb-3">
                  Ofrecemos soluciones para procesos de:
                </h3>
              </motion.div>
              <motion.div
                ref={refTextos3}
                variants={animacionTextos3}
                initial="hidden"
                animate={controlTextos3}
              >
                <ul className="list-disc mb-5 pl-5 text-justify">
                  <li className="text">Soldadura.</li>
                  <li className="text">Lijado y pulido.</li>
                  <li className="text">Metalizado por proyección.</li>
                  <li className="text">
                    Granallado y sandblasteado de piezas.
                  </li>
                  <li className="text">
                    Colección de nieblas y vapores generados en tinas de
                    tratamientos termoquímicos.
                  </li>
                  <li className="text">Cabinas de pintura.</li>
                  <li className="text">
                    Nieblas en procesos de rectificado CNC.
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
          <hr className="divider" />
          <div className="industria flex-col flex my-10 md:flex-row md:items-end md:px-6">
            <VideoIndustria src={VideoAlimentaria} />
            <div className="industria-description md:w-1/2 md:pl-3 lg:pl-10">
              <motion.div
                ref={refTitulo4}
                variants={animacionTitulo4}
                initial="hidden"
                animate={controlTitulo4}
              >
                <h2 className="title text-justify mb-3">
                  Industria Alimentaria
                </h2>
                <h3 className="subtitle text-justify mb-3">
                  Atención a problemas de colección de polvos combustibles en:
                </h3>
              </motion.div>
              <motion.div
                ref={refTextos4}
                variants={animacionTextos4}
                initial="hidden"
                animate={controlTextos4}
              >
                <ul className="list-disc mb-5 pl-5 text-justify">
                  <li className="text">Venteos.</li>
                  <li className="text">Mezclas y pesaje.</li>
                  <li className="text">Llenadoras de producto.</li>
                  <li className="text">Bombos y válvulas rotatorias.</li>
                  <li className="text">Bandas transportadoras.</li>
                  <li className="text">Elevadores de cangilones.</li>
                  <li className="text">Transportadores helicoidales.</li>
                  <li className="text">Tamizadoras de producto.</li>
                </ul>
              </motion.div>
            </div>
          </div>
          <hr className="divider" />
          <div className="industria flex-col flex md:flex-row-reverse my-10 md:items-end md:px-6">
            <VideoIndustria src={VideoQuimica} />
            <div className="industria-description md:w-1/2 md:pr-3 lg:pr-5">
              <motion.div
                ref={refTitulo5}
                variants={animacionTitulo5}
                initial="hidden"
                animate={controlTitulo5}
              >
                <h2 className="title text-justify mb-5">Industria Química</h2>
                <h3 className="subtitle text-justify mb-3">
                  Control de fuentes de contaminación en el aire en aplicaciones
                  como:
                </h3>
              </motion.div>
              <motion.div
                ref={refTextos5}
                variants={animacionTextos5}
                initial="hidden"
                animate={controlTextos5}
              >
                <ul className="list-disc mb-5 pl-5 text-justify">
                  <li className="text">
                    Proyectos para control de polvos combustibles.
                  </li>
                  <li className="text">Adición de mezclas.</li>
                  <li className="text">
                    Captura de COV's en agitadores de mezclas líquidas.
                  </li>
                  <li className="text">
                    Emisiones en laboratorios de calidad.
                  </li>
                  <li className="text">Llenado de sacos y bolsas.</li>
                  <li className="text">Llenado de bidones.</li>
                  <li className="text">Venteos en silos y tolvas.</li>
                  <li className="text">
                    Reduccion de riesgos de incendio y ventilación de espacios
                    de proceso.
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
          <hr className="divider" />
        </div>
      </section>
    </>
  );
};
