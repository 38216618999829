import Logo from "../images/logo-chico.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const Navbar = () => {
  return (
    <>
      <nav
        id="navbar"
        className="sticky top-0 w-full h-16 px-2 sm:px-6 lg:px-8 flex justify-between items-center"
      >
        <div className="flex flex-shrink-0 items-center">
          <img className="h-10 md:h-12 w-auto" src={Logo} alt="Logo Ecoinde" />
        </div>
        <div>
        <a
            href="mailto:gquintero@ecoinde.com"
            className="mx-2 icon"
          >
            <FontAwesomeIcon icon={faEnvelope}/>
          </a>
          <a
            href="https://www.linkedin.com/company/ecoinde-colecciondepolvosynieblas/"
            className="mx-2 icon linkedin"
          >
            <FontAwesomeIcon icon={faLinkedin}/>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5214422590821&text=Hola%20necesito%20informaci%C3%B3n%20sobre%20colectores%20y%20filtros!"
            className="mx-2 icon whatsapp"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <a href="tel:+5214422590821" className="mx-2 icon">
            <FontAwesomeIcon icon={faMobileScreen}/>
          </a>
        </div>
      </nav>
    </>
  );
};
