import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const VideoIndustria = ({ src }) => {
  const videoRef = useRef(null);

  const iniciarReproduccion = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error al reproducir el video:", error);
      });
    }
  };

  return (
    < div className="md:w-1/2 md:mb-0 flex-column">
      <div
        className="industria-asset mb-3 rounded-lg overflow-hidden "
        onMouseEnter={iniciarReproduccion}
      >
        <video
          ref={videoRef}
          src={src}
          controlsList="nodownload"
          loop
          controls
        />
      </div>
      <div className="mb-5">
        <h3 className="subtitle-small text-justify mb-5">
          Contáctanos para ofrecerte la mejor solución.
        </h3>
        <button className="cta text">
          <a href="mailto:gquintero@ecoinde.com" className="mx-2 icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </button>
      </div>
    </div>
  );
};
