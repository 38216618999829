import Logo from "../images/logo-chico.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  return (
    <>
      <footer id="footer" className="w-full py-6 md:py-2">
        <div className=" flex flex-col justify-center md:flex md:items-center">
          <div className="my-5 text-center md:w-1/3">
            <a className="icon" href='https://ecoinde.com/aviso_de_privacidad.pdf'>Aviso de Privacidad</a>
          </div>
          <div className="logo mx-auto">
            <img className="" src={Logo} alt="Ecoinde Logo" />
          </div>
          <div className="my-5 mx-auto md:w-1/3 text-center">
            <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Terra+Park+Centenario/@20.6713233,-100.295987,17.75z/data=!4m6!3m5!1s0x85d35dcf270dfe19:0xdb94f925e2e6c09e!8m2!3d20.672348!4d-100.2962503!16s%2Fg%2F11dym4yvr9?entry=ttu"><FontAwesomeIcon className="icon" icon={faLocationDot} />&nbsp;Paseo Centenario del Ejército Mexicano km. 1 + 037 Terra Park Centenario Bodega #38 E, El Marqués, Querétaro. 76240</a>
          </div>
        </div>
      </footer>
    </>
  );
};
