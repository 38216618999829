import VideoHeroHorizontal from "../videos/heroHorizontal.mp4";
import VideoHeroVertical from "../videos/heroVertical.mp4";

export const Hero = () => {
  return (
    <>
      <section id="hero" className="">
        <div className="container mx-auto px-8 sm:px-12 md:mb-0 rounded-lg overflow-hidden">
          <video
            className="video-vertical mx-auto sm:hidden"
            autoPlay={true}
            muted
            loop
            src={VideoHeroVertical}
          ></video>
          <video
            className="video-horizontal mx-auto hidden sm:flex sm:w-9/12 lg:w-11/12"
            autoPlay={true}
            muted
            loop
            src={VideoHeroHorizontal}
          ></video>
        </div>
      </section>
    </>
  );
};
