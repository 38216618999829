import React from 'react';
import WhatsappLogo from "../images/whatsapp.png";

export const Whatsapp = () => {
    const handleWhatsappClick = (url) => {
        const gtag_report_conversion = (url) => {
            var callback = () => {
                if (typeof(url) !== 'undefined') {
                    window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                'send_to': 'AW-10835319969/_re1CKmoqq4ZEKG5164o',
                'event_callback': callback
            });
            return false;
        };

        // Llamar a la función de conversión de Google Ads
        gtag_report_conversion();
    };

    return (
        <div id="whatsapp">
            <a
                href="https://api.whatsapp.com/send?phone=5214422590821&text=Hola%20necesito%20informaci%C3%B3n%20sobre%20colectores%20y%20filtros!"
                className="mx-2 icon"
                onClick={handleWhatsappClick()} // Llamar a la función al hacer clic
            >
                <img src={WhatsappLogo} alt="" />
            </a>
        </div>
    );
};
